/*****************************************************************************
 * UmanIT - Counter increment
 *****************************************************************************/

let countersBlock = document.querySelectorAll(".counter-container");
const formCart = Array.from(document.getElementsByName("sylius_cart"));

countersBlock.forEach(function (counterBlock) {
  const quantityContainer = counterBlock.querySelector(".counter-container input[type='number']");
  const btnAdd = counterBlock.querySelector("[data-quantity='add']");
  const btnRemove = counterBlock.querySelector("[data-quantity='remove']");
  const quantityDisplayer = counterBlock.querySelector("[data-quantity='result']");

  if (quantityContainer && btnAdd && btnRemove && quantityDisplayer) {
    displayQuantity(quantityContainer, quantityDisplayer);

    btnAdd.addEventListener("click", function () {
      increaseQuantity(quantityContainer);
      displayQuantity(quantityContainer, quantityDisplayer);
      submitForm();
    });

    btnRemove.addEventListener("click", function () {
      decreaseQuantity(quantityContainer);
      displayQuantity(quantityContainer, quantityDisplayer);
      submitForm();
    });

    if(formCart.length && parseInt(quantityDisplayer.innerHTML) < 2) {
      btnRemove.remove();
    }
  }
});

function displayQuantity(inputNumber, quantityDisplayer) {
  quantityDisplayer.innerHTML = inputNumber.value;
}

function increaseQuantity(inputNumber) {
  return inputNumber.value++;
}

function decreaseQuantity(inputNumber) {
  let value = inputNumber.value;
  value--;
  if (value < 1) {
    value = 1;
  }

  inputNumber.value = value;
}

function submitForm() {
  if (formCart) {
    formCart[0].submit();
  }
}
